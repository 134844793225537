<template>
  <div id="rulesApp" class="position-relative">
    <modal :title="modalTitle" v-if="activateModal" :modalContentName="modalContentName" @hideModal="hideModal" :modalData="modalData" ref="modal"></modal>
    <div class="d-flex justify-content-between">
      <div class="add-rules-container">
        <button class="btn btn-sm btn-outline-success" @click="addRule">
          <i class="fa fa-sticky-note-o"></i>
          Add Rule
        </button>
        <button class="btn btn-sm btn-outline-success" @click="addBatchRule">
          <i class="fa fa-clone"></i>
          Batch Add Rule
        </button>
        <button class="btn btn-sm btn-outline-success" @click="addSecondaryRule">
          <i class="fa fa-calendar"></i>
          Add Secondary Rule
        </button>
        <button v-if="this.project === 'COSTCO'" class="btn btn-sm btn-outline-success" @click="addDDSRule">
          <i class="fa fa-bolt"></i>
          Add DDS Rule
        </button>
      </div>
      <button class="btn btn-sm btn-danger" @click='showDeletedRules' v-if="!deletedTableActive">
        Deleted
        <span class="badge">{{deletedRulesAmount}}</span>
      </button>
      <button class="btn btn-sm btn-info" @click='showRules' v-else>Active Rules</button>
    </div>
    <template v-if="!deletedTableActive">
      <rules-notice
          :promotion="promo"
          :rules="rulesTableData"
      />
    </template>
    <info-table
        :loading="loading"
        :deletedTableActive="deletedTableActive"
        :allowDeleteAction="allowDeleteAction"
        :draggableTableItems="draggableTableItems"
        :rowsData="rulesTableData"
        :promotion="promo"
        @edit="editRule"
        @remove="deleteRule"
        @dismissWarning="dismissWaring"
        @dragItemsEnd="changeRulesOrder"/>
    <a class="btn btn-sm btn-warning mt-2" :href="changesLink">Changes</a>

    <Loader v-if="loading"/>
  </div>
</template>

<script>
import './../../optionsFormatter.js';
import infoTable from './../shared_components/table/table';
import modal from './../shared_components/modal/modal';
import Loader from './../shared_components/Loader.vue';
import rulesNotice from './rulesNotice.vue';

const SECONDARY_RULE_TYPES = ['SecondaryFixedRule', 'SecondaryVariableRule'];
const PRIMARY_RULE_CONFLICT_MESSAGE = 'Attention! You have just created a duplicate rule that will result ' +
  'in an error to the customer. Please adjust your setup or create a secondary rule to issue multiple bonuses.';
const SECONDARY_RULE_CONFLICT_MESSAGE = 'Attention! You have just created one or more duplicate rules during ' +
  'a single timeframe, which will result in multiple bonuses being sent to the customer. Please review for ' +
  'accuracy and adjust as needed.';

export default {
  name: 'rules',
  props: {
    promotion: {
      required: true,
      type: Object,
    },
  },
  components: {
    'info-table': infoTable,
    'rules-notice': rulesNotice,
    modal,
    Loader,
  },
  data() {
    return{
      modalTitle: 'Edit Rule',
      activateModal: false,
      ruleToEdit: {},
      rulesData: [],
      products: [],
      modalData: {},
      modalContentName: '',
      deletedRules: 0,
      currency: '',
      deletedRulesAmount: 0,
      allowDeleteAction: false,
      deletedTableActive: false,
      draggableTableItems: true,
      loading: false,
      promo: JSON.parse(this.promotion),
    };
  },
  computed: {
    changesLink(){
      return `${window.location.href}/changes`
    },
    XCRSFToken() {
      return document.querySelector("meta[name='csrf-token']").content
    },
    rulesTableData() {
      if(this.rulesData.length > 0){
      return this.rulesData.map(rule => {
        let sponsoringProduct = this.products.find(product => product.id === rule.sponsoringProductId);
        let bonusProduct = this.products.find(product => product.id === rule.bonusProductId);
        let amount = '';
        let overriden = true;
        let {street_timezone, street_date} =  rule;

        // Pass default product object if product was not found
        if (sponsoringProduct == undefined)
          sponsoringProduct = this.deletedProduct();
        if (bonusProduct == undefined)
          bonusProduct = this.deletedProduct();

        if ( rule.amount.length ){
          let amountArray = [];
          rule.amount.forEach(amount => amountArray.push(`[${amount.start} - ${amount.end}] - ${amount.bonus} ${amount.currency} `));
          amount = amountArray
        } else {
          amount = `${rule.amount.value} ${rule.amount.currency}`
        }

        if (street_date == null) {
          street_date = rule.bonus_product.street_date;
          overriden = false;
        }

        const secondaryRule = !!(rule.activationStartDate && rule.activationEndDate && rule.claimEndDate);

        return {
          id: rule.id,
          primary: rule.primary,
          type: rule.type,
          sponsoringProductData: { mainInfo: sponsoringProduct.upc, description: sponsoringProduct.description, deleted: sponsoringProduct.deleted, link: '/admin/projects/' + sponsoringProduct.project_id +'/products/'+ sponsoringProduct.id},
          bonusProductData: { mainInfo: bonusProduct.upc, description: bonusProduct.description, deleted: bonusProduct.deleted, link: '/admin/projects/' + bonusProduct.project_id +'/products/'+ bonusProduct.id },
          amount: amount,
          street_date: {
            date: street_date,
            street_timezone: street_timezone,
            overriden: overriden
          },
          secondaryRule,
          secondaryData: secondaryRule && {
            activationStartDate: rule.activationStartDate,
            activationEndDate: rule.activationEndDate,
            claimStartDate: rule.claimStartDate,
            claimEndDate: rule.claimEndDate,
            importance: rule.importance,
          },
          presale:  this.presale,
          external: this.external,
          approved: rule.approved,
          warnings: rule.warnings || [],
        }
      })} else {
        return this.rulesData
      }
    }
  },
  mounted() {
    this.loading = true;

    this.$http.get(`${window.location.href}.json`)
      .then((response) => {
        const {data} = response;
        this.products = data.products;
        this.rulesData = data.rules;
        this.deletedRules = data.deleted;
        this.deletedRulesAmount = data.deleted.length;
        this.currency = data.currency;
        this.presale = data.presale || false;
        this.external = data.external || false;
        this.project = data.project;
        this.allowDeleteAction = data.allow_delete_action || false;
        this.branchTimezone = data.branch_timezone;
      }).finally(() => {
        this.loading = false;
        this.applyTooltips();
    });
  },
  methods: {
    applyTooltips() {
      setTimeout(() => { // wait for table to render
        $('[data-toggle="tooltip"]').tooltip();
      }, 600);
    },

    deletedProduct() {
      return {
        upc: '< deleted >',
        amountType: '< Invalid >',
        deleted: true
      }
    },
    addBatchRule() {
      this.modalContentName = 'batchAddRule';
      this.activateModal = true;
      this.modalTitle = 'Batch Add Rule';
      this.modalData = {
        promotion: this.promo,
        products: this.products,
        currency: this.currency,
        presale: this.presale,
        external: this.external,
        ddsRule: false
      };
    },
    addRule() {
      this.modalContentName = 'rulesForm';
      this.activateModal = true;
      this.modalTitle = 'New Rule';
      this.modalData = {
        promotion: this.promo,
        products: this.products,
        currency: this.currency,
        presale: this.presale,
        external: this.external,
        branchTimezone: this.branchTimezone,
        ddsRule: false
      };
    },

    addSecondaryRule() {
      this.modalContentName = 'rulesForm';
      this.activateModal = true;
      this.modalTitle = 'New Secondary Rule';
      this.modalData = {
        promotion: this.promo,
        products: this.products,
        currency: this.currency,
        presale: false,
        external: false,
        branchTimezone: this.branchTimezone,
        ddsRule: false,
        secondaryRule: true,
      };
    },

    addDDSRule() {
      this.modalContentName = 'rulesForm';
      this.activateModal = true;
      this.modalTitle = 'New DDS Rule';
      this.modalData = {
        promotion: this.promo,
        products: this.products,
        currency: this.currency,
        presale: this.presale,
        external: this.external,
        branchTimezone: this.branchTimezone,
        ddsRule: true
      };
    },
    dismissWaring(id) {
      const message = 'Are you sure you want to acknowledge and remove the alert?';
      const url = `${window.location.href}/${id}/acknowledge_warning.json`;

      $('.tooltip').remove();

      if (confirm(message)) {
        this.loading = true;
        this.$http.post(url, null, {
          headers: {'X-CSRF-Token': this.XCRSFToken}
        }).then(() => {
          const rule = this.rulesData.find(rule => rule.id === id);
          rule.warnings = [];
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    editRule(id) {
      const rule = this.rulesData.find( rule => rule.id === id )
      this.modalContentName = 'rulesForm';
      const secondaryRule = !!(rule.activationStartDate && rule.activationEndDate && rule.claimEndDate);
      this.modalData = {
        promotion: this.promo,
        presale: this.presale,
        external: this.external,
        rule,
        products: this.products,
        currency: this.currency,
        position: this.rulesData.findIndex( rule => rule.id === id ),
        branchTimezone: this.branchTimezone,
        ddsRule: rule.type.includes('DDS'),
        secondaryRule,
      };
      this.activateModal = true;
      this.modalTitle = 'Edit Rule'
    },
    deleteRule(id) {
      this.loading = true;
      document.querySelector('span.rules_count').innerText = this.rulesData.length - 1
      this.$http.delete(`${window.location.href}/${id}.json`,{ headers: {'X-CSRF-Token': this.XCRSFToken}})
           .then((response) => {
             this.deletedRulesAmount++
             this.$http.get(`${window.location.href}.json`).then((response) => {
               this.rulesData = response.data.rules
               });
            }).finally(() => {
              this.loading = false;
              this.applyTooltips();
            });
    },
    hideModal(changesAccepted) {
      if (changesAccepted) this.duplicatingRuleCheck();
      this.$http.get(`${window.location.href}.json`).then((response) => {
        this.rulesData = response.data.rules
        document.querySelector('span.rules_count').innerText = response.data.rules.length
       }).finally(() => {
         this.applyTooltips();
      });
      this.activateModal = false;
      this.modalTitle = '';
      this.modalData = {};
    },
    changeRulesOrder(ruleIds) {
      this.loading = true;
      this.$http.post( `${window.location.href}/reorder.json`,
        {order: ruleIds} ,
        { headers: { 'X-CSRF-Token': this.XCRSFToken }}
      ).then(() => {
          let newOrder = [];

          ruleIds.forEach((id) => {
            newOrder.push(this.rulesData.find((rule) => (rule.id === id)))
          });

          this.rulesData = [...newOrder];
      }).finally(() => {
        this.loading = false;
        this.applyTooltips();
      })
    },
    showDeletedRules() {
      this.deletedTableActive = true;
      this.draggableTableItems = false;
      this.rulesData = this.deletedRules;
    },
    showRules() {
      this.deletedTableActive = false;
      this.draggableTableItems = true;
      this.loading = true;
      this.$http.get(`${window.location.href}.json`)
        .then((response) => {
          this.rulesData = response.data.rules
        }).finally(() => {
          this.loading = false;
      });
    },
    duplicatingRuleCheck() {
      if (this.modalContentName !== 'rulesForm') return;

      const form = this.$refs.modal.$refs.modalBody.$refs.form;
      const bpId = form.currentBonusProduct?.id;
      const spId = form.currentSponsoringProduct?.id;
      if (!bpId || !spId) return;

      const isSecondaryRule = this.modalData.secondaryRule === true;
      const id = this.modalData.rule?.id;
      const rules = this.rulesData
        .filter(isSecondaryRule
          ? (rule) => SECONDARY_RULE_TYPES.includes(rule.type)
          : (rule) => !SECONDARY_RULE_TYPES.includes(rule.type))
        .filter(rule => rule.bonusProductId === bpId && rule.sponsoringProductId === spId && rule.id !== id)
      if (rules.length === 0) return;

      if (!isSecondaryRule) {
        alert(PRIMARY_RULE_CONFLICT_MESSAGE);
        return;
      }

      const cStartDate = form.secondary.claimStart
        ? this.$moment(form.secondary.claimStart).format('YYYY-MM-DD')
        : this.$moment(form.secondary.startDate).format('YYYY-MM-DD');
      const cEndDate = this.$moment(form.secondary.claimEnd).format('YYYY-MM-DD');
      const cStart = this.$moment.tz(cStartDate, form.secondary.claimStartTZ).startOf('day');
      const cEnd = this.$moment.tz(cEndDate, form.secondary.claimEndTZ).endOf('day');

      const hasOverlap = rules.some(rule => {
        const rcStart = this.$moment.tz(rule.claimStartDate || rule.activationStartDate, rule.claimStartTZ).startOf('day');
        const rcEnd = this.$moment.tz(rule.claimEndDate, rule.claimEndTZ).endOf('day');

        return cStart.isBetween(rcStart, rcEnd, undefined, '[]') || cEnd.isBetween(rcStart, rcEnd, undefined, '[]')
            || rcStart.isBetween(cStart, cEnd, undefined, '[]') || rcEnd.isBetween(cStart, cEnd, undefined, '[]');
      });

      if (hasOverlap) {
        alert(SECONDARY_RULE_CONFLICT_MESSAGE);
      }
    }
  }
}
</script>

<style scoped></style>
